import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session';
import authenticationReducer from './reducers/authenticationReducer';

// https://github.com/rt2zz/redux-persist#readme
// https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
const persistConfig = {
  key: 'root',
  storage: storage
}

// Configure nested state in store
// https://github.com/rt2zz/redux-persist#nested-persists
const authPersistConfig = {
  key: 'auth',
  storage: storage
}

// Combine reducers
// https://github.com/rt2zz/redux-persist#nested-persists
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authenticationReducer),
})

// Create and export store and persistor
export const store = createStore(rootReducer);
export const persistor = persistStore(store); 