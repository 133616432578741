import React, { Component } from "react";
import {
  Link,
  withRouter,
  RouteComponentProps,
  NavLink
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { Auth } from "aws-amplify";
import "./App.css";
import Routes from "./Routes";
import { connect } from "react-redux";
import { logout } from "./redux/actions/loginActions";

interface IProps extends RouteComponentProps<any> {
  loggedIn: boolean;
  epoch: number;
  logout: () => void;
}

interface IStore {
  auth: {
    loggedIn: boolean;
    epoch: number;
  };
}

const mapStateToProps = (state: IStore) => {
  return {
    loggedIn: state.auth.loggedIn,
    epoch: state.auth.epoch
  };
};

class App extends Component<IProps, any> {
  handleLogout: (event: any) => Promise<void>;

  constructor(props: IProps) {
    super(props);

    // console.log(props.ok);

    this.handleLogout = async (event: any) => {
      try {
        // Sigout user
        await Auth.signOut();

        // Dispatch logout redux action
        props.logout();
      } catch (e) {
        console.log(e);
      }
    };
  }

  render() {
    return (
      <>
        <Navbar collapseOnSelect bg="light">
          <Navbar.Brand>
            <Link to="/">Aquarium</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <LinkContainer to="/about">
                <Nav.Item>
                  <Nav.Link href="/about">About</Nav.Link>
                </Nav.Item>
              </LinkContainer>
              <LinkContainer to="/FC">
                <Nav.Item>
                  <Nav.Link href="/FC">FC</Nav.Link>
                </Nav.Item>
              </LinkContainer>
              <LinkContainer to="/FC/123">
                <Nav.Item>
                  <Nav.Link href="/FC">FC-123</Nav.Link>
                </Nav.Item>
              </LinkContainer>
              {this.props.loggedIn ? (
                <Nav.Item onClick={this.handleLogout}>
                  <Nav.Link href="#">Logout</Nav.Link>
                </Nav.Item>
              ) : (
                <>
                  <LinkContainer to="/signup">
                    <Nav.Item>
                      <Nav.Link href="/signup">Signup</Nav.Link>
                    </Nav.Item>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Item>
                      <Nav.Link href="/login">Login</Nav.Link>
                    </Nav.Item>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes />
      </>
    );
  }
}

// Redux integration with React Router DOM, withRouter()
// https://reacttraining.com/react-router/web/guides/redux-integration
export default withRouter(
  connect(
    mapStateToProps,
    { logout }
  )(App)
);
