export const LOGIN_SUCCESS ='LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const loginSuccess = () => (
  {
    type: LOGIN_SUCCESS
  }
)

export const loginFail = () => (
  {
    type: LOGIN_FAIL
  }
)

export const logout = () => (
  {
    type: LOGOUT
  }
)