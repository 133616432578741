import * as React from "react";
import "./NotFound.css";

const NotFound: React.FunctionComponent = () => {
  return (
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>
    </div>
  )
};

export default NotFound;
