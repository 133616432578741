import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps<{id: string}>;

const FC: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      This is React.FunctionComponent. {props.match.params.id}
    </div>
  )
}

export default FC;