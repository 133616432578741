import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginSuccess, loginFail, logout } from '../redux/actions/loginActions';
// import Login from '../containers/Login';

interface IState {
  auth: {
    loggedIn: boolean;
    epoch: number;
  }
}

const mapStateToProps = function(store: IState) {
  return {
    loggedIn: store.auth.loggedIn,
    epoch: store.auth.epoch
  }
}

// Defining mapDispatchToProps as an object
// https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
const mapDispatchToProps = {
  loginSuccess,
  loginFail,
  logout
}

type T = { 
  component: React.ComponentClass; 
  loggedIn: boolean;
  [propName: string]: any;
}

let PrivateRoute = ({ component: C, loggedIn: loggedIn, ...rest }: T) => 
<Route 
  {...rest} 
  render = { props => (
    (loggedIn) ? (<C {...props}/>)
    : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
    )
  )}
/>

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
