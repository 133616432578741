import React from "react";
// import { Badge } from 'react-bootstrap';
import { Badge } from "react-bootstrap";

export default () => {
  return (
    <>
      <div>Sensor Readings</div>
      <h3>
        Hello world <Badge variant="primary">New new new</Badge>
        <Badge variant="secondary">New New New</Badge>
        <Badge variant='success'>Success</Badge>
        <Badge variant='danger'>Danger</Badge>
      </h3>
    </>
  );
};
