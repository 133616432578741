import * as React from "react";
import { Auth } from "aws-amplify";
import { Button, Form } from "react-bootstrap";
import "./Login.css";
import { connect } from "react-redux";
import { loginSuccess, loginFail } from "../redux/actions/loginActions";

interface IState {
  setAuthStatus: (event: any) => Promise<void>;
  email: string;
  password: string;
}

interface IProps {
  loggedIn: boolean;
}

// const mapDispatchToProps = {
//   loginSuccess,
//   loginFail
// };

class Login extends React.Component<IProps, IState> {
  setAuthStatus: (event: any) => Promise<void>;

  constructor(props: any) {
    super(props);

    // console.log(JSON.stringify(props, null, 2));

    this.setAuthStatus = async (event: any) => {
      event.preventDefault();

      try {
        await Auth.signIn(this.state.email, this.state.password);

        // Dispatch login success action to Redux store
        props.loginSuccess();

        // Redirect user to the page where he came from or to the home page
        // React Router V4 is mostly a wrapper arount the [history](https://github.com/ReactTraining/history) library
        // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
        let { from } = props.location.state || { from: { pathname: "/" } };
        props.history.push(from);

        alert("Logged in");
      } catch (e) {
        props.loginFail();
        alert(e.message);
      }
    };

    this.state = {
      setAuthStatus: this.setAuthStatus,
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.id]: event.target.value
    } as any);
  };

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.setAuthStatus}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
          <Button block disabled={!this.validateForm()} type="submit">
            Login
          </Button>
          <div>{this.props.loggedIn ? <div>user</div> : <div>guest</div>}</div>
        </form>
      </div>
    );
  }
}

// This component is connect to the Redux store but is not
// subscribe to it. Heace, changes in store will not re-render
// this component.
// https://react-redux.js.org/introduction/basic-tutorial#common-ways-of-calling-connect
export default connect(
  null,
  { loginSuccess, loginFail }
)(Login);
