import * as React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';
import Home from "./containers/Home";
import Login from './containers/Login';
import About from "./containers/About";
import FC from './containers/FC';
import NotFound from "./containers/NotFound";


// type T = { 
//   childProps: any;
// };

export default () =>
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path='/login' component={Login} />
    <Route exact path="/fc" component={FC} />
    <Route exact path="/fc/:id" component={FC} />
    <PrivateRoute exact path='/about' component={About} />

    <Route component={NotFound} />
  </Switch>;
