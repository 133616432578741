import * as React from "react";

interface IProps {
  name: string;
}

const About: React.FunctionComponent<IProps> = (props) => {
  const handleButtonClick = () => {
    console.log("clicked");
  };

  return (
    <div>
      <div>This is the about page... {props.name}</div>
      <div>
        <button onClick={handleButtonClick} />
      </div>
    </div>
  );
};

About.defaultProps = {
  name: "hello"
};

export default About;
