export default {
  s3: {
    REGION: "us-west-2",
    BUCKET: "com.gnsstoday.aquarium.frontend"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_FwUxwSoA6",
    APP_CLIENT_ID: "7rl31r5gl03ug4o1ejeurkpq23",
    IDENTITY_POOL_ID: "us-west-2:f4db42a0-f140-4223-9be3-7476f8efd578"
  }
};
