import * as React from "react";
import "./Home.css";
import "./SensorReadings";
import SensorReadings from "./SensorReadings";

const Home: React.FunctionComponent = () => {
  return (
    <div className="Home">
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
        <SensorReadings />
      </div>
    </div>
  );
};

export default Home;
