import redux from "redux";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../actions/loginActions";

interface IAction {
  type: string;
  loggedIn: boolean;
  epoch: number;
}

interface IState {
  loggedIn: boolean;
  epoch: number;
}

const initialState = {
  loggedIn: false,
  epoch: 0
};

export default (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: true,
        epoch: Date.now()
      });
    case LOGIN_FAIL:
      return Object.assign({}, state, {
        loggedIn: false,
        epoch: Date.now()
      });
    case LOGOUT:
      return Object.assign({}, state, {
        loggedIn: false,
        epoch: Date.now()
      });
    default:
      return state;
  }
};
